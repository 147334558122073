<template>
    <div class="accountConfigure">
        <!-- <div class="title">账户管理</div> -->
        <div class="headBox">
            <div>
                <el-button type="primary" icon="el-icon-arrow-left" size="mini" @click="back()">返回</el-button>
                <el-button type="primary" icon="el-icon-plus" size="mini" @click="addAccount()">创建账号</el-button>
            </div>

            <div class="right">
              
                <el-input placeholder="输入手机号" clearable v-model="phoneNumber" size="mini">
                    <template slot="prepend">手机号:</template>
                </el-input>
                
            </div>
        </div>
        <table-list
            :ButtonWidth="140"
            :tableData="tableData"
            :colrs="colrs"
            :total="total"
            :pagesize="pagesize"
            :pageIndex="pageIndex"
            @currentChange="currentChange"
            @handleSizeChange="handleSizeChange"
        >
            <template #TableItem="scope">
                
                <span v-if="scope.colr.prop == 'name'">
                    <span>{{ $route.query.name }}</span>
                </span>
            </template>
            <template #Button="scope">
               
                <el-button size="mini" type="text" @click="edit(scope.row)" v-if="!scope.row.isAdmin">编辑</el-button>
                <el-button size="mini" type="text" style="color: #f56c6c" @click="del(scope.row)" v-if="!scope.row.isAdmin">删除</el-button>
                <span  v-if="scope.row.isAdmin">管理员账号</span>
            </template>
        </table-list>

        <el-dialog
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose"
            :show-close="false"
            class="zhBialog"
            center
        >
            <el-card class="box-card userInfo">
                <div slot="header" class="clearfix">
                    <span class="userInfo_title">{{ dialogTitle }}</span>
                </div>
                <el-form :model="userForm" ref="userForm" label-width="120px"  class="userForm">
                    <el-form-item label="租户名称" prop="tenantId">
                        <el-select v-model="this.$route.query.name" disabled placeholder="选择租户名称" size="small">
                            <el-option
                                v-for="item in tenantIdOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="账户手机号" prop="date">
                        <el-input
                            size="small"
                            v-model="userForm.phoneNumber"
                            placeholder="输入手机号"
                            style="width: 352px"
                        ></el-input>
                    </el-form-item>
                   
                </el-form>
            </el-card>
            <span slot="footer" class="dialog-footer" v-if="dialogTitle!='查看账号'">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import TableList from "../../components/comm/TableList.vue";
import accountConfigure from "../../api/accountConfigureApi";
export default {
    name: "accountConfigure",
    components: { TableList },
    data() {
        return {
            tableData: [],
            colrs: [
                { label: "租户ID", prop: "tenantId" },
                { label: "租户名称", prop: "name" },
                { label: "登录手机号", prop: "phoneNumber" },
                
            ],
            total: 0,
            pagesize: 20,
            pageIndex: 1,
            id: "",
            phoneNumber: "",

            dialogVisible: false,
            userForm: {},
            tenantIdOptions: [],


            dialogTitle: "创建账号",
            userId:''
        };
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData() {
            accountConfigure
                .getList({
                    phoneNumber: this.phoneNumber,
                 
                    tenantId: this.$route.query.tenantId,
                    pageSize: this.pagesize,
                    pageNumber: this.pageIndex
                })
                .then(res => {
                    if (res.success) {
                        this.tableData = res.data.records;
                        
                        if (this.$utils.userForm && this.$utils.userForm.isAdmin) {
                            let isAdmin=this.$utils.userForm.isAdmin
                            if (isAdmin) {
                                this.tableData.forEach(item=>{
                                    if (item.phoneNumber==this.$utils.userForm.phoneNumber) {
                                        item.isAdmin=true
                                    }
                                })
                            }
                        }
                       
                    }
                });
        },

        addAccount() {
           

            this.$set(this.userForm,'phoneNumber','')
        
            this.dialogVisible = true;
            this.dialogTitle = "创建账号";
        },
        edit(row) {
           
    
            this.userId=row.id
            this.userForm=JSON.parse(JSON.stringify(row))
            this.dialogVisible = true;
            this.dialogTitle = "编辑账号";
        },

        check() {
            
            this.dialogVisible = true;
            this.dialogTitle = "查看账号";
        },
        back() {
            this.$router.back();
        },
        del(row) {
            accountConfigure
                .delete({
                    id: row.id
                })
                .then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "删除成功"
                        });
                        this.getData();
                    } else {
                        this.$message({
                            type: "warning",
                            message: "删除失败"
                        });
                    }
                });
        },

        submit() {
            if (this.dialogTitle=='编辑账号') {
                accountConfigure
                .update({
                    username: "测试账户",
                    phoneNumber: this.userForm.phoneNumber,
                    tenantId: this.$route.query.tenantId,
                  
                    id: this.userId
                })
                .then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "保存成功"
                        });
                        this.dialogVisible = false;
                        this.getData();
                    } else {
                        this.$message({
                            type: "warning",
                            message: "保存失败"
                        });
                    }
                });
            }else{
                accountConfigure
                .add({
                    username: "测试账户",
                    phoneNumber: this.userForm.phoneNumber,
                    tenantId: this.$route.query.tenantId,
                    
                })
                .then(res => {
                    if (res.success) {
                        this.$message({
                            type: "success",
                            message: "保存成功"
                        });
                        this.dialogVisible = false;
                        this.getData();
                    } else {
                        this.$message({
                            type: "warning",
                            message: "保存失败"
                        });
                    }
                });
            }

            
        },

        handleClose() {},

        currentChange(pageIndex) {
            this.pageIndex = pageIndex;
        },
        handleSizeChange(pagesize) {
            this.pageIndex = 1;
            this.pagesize = pagesize;
        }
    }
};
</script>
<style lang="scss" scoped>
.accountConfigure {
    background: #ffffff;
    padding: 20px;
    border-radius: 4px;
    height: 89vh;
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    .title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    .headBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        h3 {
            font-size: 18px;
            font-weight: 500;
            color: #222222;
            line-height: 1;
        }
        .right {
            display: flex;
            align-items: center;
            gap: 10px;
            .select {
                display: flex;
                align-items: center;
                .selectTit {
                    background-color: #f5f7fa;
                    color: #909399;
                    vertical-align: middle;
                    display: table-cell;
                    position: relative;
                    border: 1px solid #dcdfe6;
                    border-right: 0;
                    border-radius: 4px 0 0 4px;
                    padding: 0 20px;
                    height: 26px;
                    line-height: 26px;
                    white-space: nowrap;
                }
                .el-select {
                    width: 180px;
                    /deep/.el-input__inner {
                        border-radius: 0 4px 4px 0;
                    }
                }
            }
        }
    }
    .box-card {
        // width: 600px;
        /deep/.el-card__header {
            background: #f4f4f5;
        }
    }
    .userInfo_title {
        font-size: 16px;
        color: #222222;
        margin-bottom: 40px;
        font-weight: 700;
    }
    .zhBialog {
        /deep/.el-dialog__header,
        /deep/.el-dialog__body {
            padding: 0;
        }
    }
}
</style>
